@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

* {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
}
.thp {
    font-family: 'Pacifico', cursive !important;
    text-align: center;
  }