@import "~react-image-gallery/styles/css/image-gallery.css";
/* colors  */
/* primary-color: #FAFAF9
secondary-color: #9C9A84
accent-color: #687250 */

/* end colors  */
* {
  font-family: Arial, Helvetica, sans-serif !important;
  margin: 0;
  padding: 0;
}

/* Nav Bar   */

.white {
  background-color: #fff;
}

.nav-link {
  color: #333;
  text-decoration: none;
  padding: 10px;
  font-weight: bold;
}


/* Component style Sheets  */
.btn {
  padding: .5rem;
  color: #FAFAF9;
  font-weight: 800;
  border: .15rem solid #687250;
  border-radius: .8rem;
}

.btn:hover {
  border: .15rem solid #FAFAF9;
  border-radius: .4rem;
  background-color: #687250;
  color: #FAFAF9;
}

/* Header section  */
.main {
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  /* font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FAFAF9; */
}




/* about section  */

.redirect {
  text-decoration: none;
  color: #687250;
}

.redirect:link,
.redirect:visited {
  text-decoration: none;
  color: #687250;
}

.intro {
  padding: 1.5rem 1.5rem;
  padding-bottom: 0rem;
  text-align: justify;
}

.about-section {
  background-color: #FAFAF9;
  color: #1D190C;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
  text-align: center;
}

.our-features {
  margin: auto;
  padding: 0.5rem 1rem;
}

.features {
  width: 20rem;
  /* height: 15rem; */
  border: .15rem solid #9C9A84;
  margin-bottom: 0.5rem;
  border-radius: .8rem;
  box-shadow: 1rem 1rem 0.7rem #9C9A84;
  margin: .5rem;
}

.card-img {
  border-radius: .8rem;
}

.features-text {
  font-size: .8rem;
  color: #000;
}

.goals {
  padding: 2rem 1rem;
}

.donate {
  padding: 2rem 5rem;
  text-align: center;
}

.donate-btn {
  background-color: #687250;
}

ul {
  list-style: square;
}

/* services */

.services-section {
  /* padding: 2rem 0.2rem; */
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
  text-align: center;
  background-color: #fff;
}


/* services carousel  */
.item {
  min-height: 10rem;
  min-width: 15rem;
  padding: 2rem;
}

.inner-carousel {
  display: flex;
}

.carousel {
  cursor: grab;
  overflow: hidden;
}

/* book now section  */

.donate {
  text-align: justify;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
  color: #3F493D;
}

.donate-center {
  text-align: center !important;
  padding: 1rem 0 !important;
}



/* footer section  */



.footer {
  background-color: #FAFAF9;
  padding: 2rem;
  font-size: 1rem;
  color: #2D2D34;
  text-align: center;
}

.icons {
  color: #687250;
  font-size: 1.3rem;
}

.footer-link {
  color: #687250;
  text-decoration: none;
  font-weight: 500;
}

.footer-link:hover {
  color: #687250;
  text-decoration: none;
  font-weight: 500;
}



/* Other style sheets  */

.center {
  text-align: center;
}

.justify-content {
  text-align: justify;
  padding: 5rem;
}

.justify-text {
  text-align: justify;
}

.list-left {
  text-align: left;
  list-style-type: circle;
}

.list-left ul li {
  display: inline-block;
  /* Display list items horizontally */
  margin-right: 10px;
  /* Add some spacing between list items */
}

.green {
  color: #687250;
}

.ideate {
  text-decoration: none;
}

.gallery {
  /* position: 'fixed'; */
  top: 0;
  left: 0;
  width: '100%';
  height: '60vh' !important; 
  background-color: '#000';
  /* z-index: 9999; */
}